import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/Seo"

export default function Template({ data }) {
  const lore = data.markdownRemark

  return (
    <>
      <Seo title={lore.frontmatter.title} />
      <h1>Lore: {lore.frontmatter.title}</h1>
      <hr />
      <small>Published {lore.frontmatter.date}</small>
      <div className="lore">
        <div className="frontmatter">
          <h1 className="lore-title">{lore.frontmatter.title}</h1>
          {lore.frontmatter.author && (
            <p className="lore-author">By {lore.frontmatter.author}</p>
          )}
          {lore.frontmatter.publisher && (
            <p className="lore-publisher">{lore.frontmatter.publisher}</p>
          )}
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: lore.html }}
          className="lore-content"
        />
      </div>
    </>
  )
}

export const loreQuery = graphql`
  query LoreByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        date
        title
        author
        publisher
      }
    }
  }
`
